@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.App {
  text-align: center;
  font-family: "Varela Round", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* NEW CSS */

@media (max-width: 600px) {
  .fab {
    display: none;
  }
  header {
    flex-direction: column;
  }
}

header {
  font-family: "Varela Round", sans-serif;
}

.jumbo-splash {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.jumbo-splash img {
  width: 100%;
  height: auto;
  display: block; /* removes any space below the image */
}

.container {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
  height: 100px; /* Adjust as needed */
  width: 100%; /* Adjust the width as per your requirement */
}

.text {
  font-weight: bold;
  font-family: "Varela Round", sans-serif;
  font-size: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  background-color: transparent;
  transition: background-color 0.5s ease;
  padding: 20px;
}

.top {
  left: 50%;
}

.bottom {
  right: 50%;
}

.slide-left {
  animation: slideLeft 2s forwards;
}

.slide-right {
  animation: slideRight 2s forwards;
}

.pricing {
  left: 30%;
  top: 20%;
}

@keyframes slideLeft {
  0% {
    transform: translate(-100%, -50%);
  }
  50% {
    transform: translate(0, -50%);
    background-color: transparent;
  }
  100% {
    background-color: #fb8800;
  }
}

@keyframes slideRight {
  0% {
    transform: translate(10%, -500%);
  }
  50% {
    transform: translate(0, -50%);
    background-color: transparent;
  }
  100% {
    background-color: #1ee9ed;
  }
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .jumbo-splash {
    height: auto;
  }

  .jumbo-splash img {
    width: 100%;
    height: auto;
  }

  .text {
    font-weight: bold;
    font-family: "Varela Round", sans-serif;
    font-size: 20px;
    padding: 10px;
  }
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Adjust spacing between images */
  padding: 80px;
}

.image-box {
  position: relative;
  width: calc(25% - 10px); /* Adjust width for spacing */
  height: 400px; /* Adjust height as needed */
  overflow: hidden;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-box:hover img {
  transform: scale(1.1);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #fb8800;
  padding: 10px;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
}

.image-box:hover .overlay-text {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .image-container {
    padding: 80px 20px;
  }
  .image-box {
    width: calc(50% - 10px); /* Adjust for smaller screens */
  }
}

.home-title {
  font-family: "Varela Round", sans-serif;
  font-size: 40px;
}

.phone-image-container {
  position: relative;
  max-width: 500px;
  height: 500px;
  overflow: hidden;
  justify-content: space-around;
}

.cutout-image {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 2;
}

.moving-background {
  width: 70%;
  height: 400%;
  left: 10%;
  position: absolute;
  background-image: url(http://localhost:3000/static/media/bubble-apps.cfe36cb….png);
  background-size: contain;
  background-repeat-y: repeat;
  animation: moveBackground 50s linear infinite;
  background-image: url("../public/site-images/bubble-apps.png");
}

@keyframes moveBackground {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0%);
  }
}

/* Pricing */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  padding: 10px;
  margin: 50px 10px;
}

.grid-item {
  position: relative;
  perspective: 1000px; /* Used for 3D transformations */
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease-in-out;
  backface-visibility: hidden; /* Hide the back of the element when it is flipped */
}

.grid-item .overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  transition: transform 0.5s ease-in-out;
  backface-visibility: hidden;
}

.grid-item .card-back {
  position: absolute;
  padding: 10px;
  opacity: 0;
}

/* Flip effect on hover */
.grid-item:hover img,
.grid-item:hover .overlay {
  transform: rotateY(180deg);
}

.grid-item:hover .card-back {
  opacity: 1;
}

/* Responsive text size */
@media (max-width: 600px) {
  .overlay {
    font-size: 12px;
  }
}
