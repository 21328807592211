/* Sticky footer styles */
.sticky-footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333; /* Dark background */
  color: white;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.footer-section {
  margin: 0 10px;
}

.subscribe-section form {
  margin: 10px 0;
}

.subscribe-section input[type="email"] {
  padding: 10px;
  margin-right: 5px;
}

.subscribe-section input[type="submit"] {
  padding: 10px;
  background-color: #007bff; /* Bootstrap primary color */
  border: none;
  color: white;
}

.footer-bottom {
  background-color: #222; /* Darker background for bottom part */
  padding: 10px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin: 10px 0;
  }
}

/* Hover effects and transitions */
.footer-section img {
  transition: transform 0.3s ease;
}

.footer-section img:hover {
  transform: scale(1.1);
}
